<template>
  <div>
    <div class="compont_p">快捷采购</div>

    <el-table :data="tableData" class="mt_20" @sort-change="sortChange">
      <!-- <el-table-column type="selection" align="center" width="55" /> -->
      <el-table-column prop="name" label="商品信息" width="260">
        <template slot-scope="{ row }">
          <div class="flex_row_aic" @click="handleClick(row)">
            <img style="width: 87px; height: 87px" :src="row.image.url" alt="" />
            <div class="info">
              <p class="shenglue_2">{{ row.title }}</p>
              <p class="c6">{{ row.specification }}</p>
              <div class="flex_row" style="flex-flow: row;flex-wrap: wrap;">
                <div class="tag" style="max-width:66px;margin-right: 3px;margin-bottom: 3px;" v-for="item in row.tags" :key="item.title" :style="`color: #${item.color}; border: 1px solid #${item.color};`">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column label="厂家" align="center" width="150">
        <template slot-scope="{ row }">
          <span class="shenglue_1">{{ row.manufacturer }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="validity_date" label="效期" align="center" />
      <el-table-column prop="price" label="单价" align="center" />
      <el-table-column prop="cgcs" label="采购次数" :sortable="'custom'" align="center" />
      <el-table-column prop="cgsl" label="采购数量" :sortable="'custom'" align="center" />
      <el-table-column label="操作" align="center">
        <template slot-scope="{ row }">
          <div class="btn flex_c_c" @click="handleAdd(row)">加入购物车</div>
        </template>
      </el-table-column>
    </el-table>
    <pagination :total="total" @handleCurrentChange="handleCurrentChange" @handleSizeChange="handleSizeChange" />
  </div>
</template>

<script>
import pagination from "@/components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      tableData: [],
      total: 0,
      page: 1,
      page_size: 10,
      order_key: "", //105采购次数 106采购数量
      order_desc: "", //101升序，102降序
    };
  },
  created() {
    this.getGoods();
  },
  methods: {
    getGoods() {
      this.$api("goods.getGoods", {
        discover_type: 103,
        order_key: this.order_key,
        order_desc: this.order_desc,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal;
      });
    },
    handleAdd(item) {
      if (item.can_buy == 102) {
        this.$message.error(item.can_buy_str);
        return;
      }
      let p = {
        id: item.id,
        number: item.cart_number,
      };
      this.$api("goods.addCart", p).then(() => {
        this.$message.success("加入购物车成功");
        this.$store.dispatch("getBadge");
      });
    },
    sortChange(e) {
      if (e.order == "descending") {
        this.order_desc = 102;
      } else {
        this.order_desc = 101;
      }
      if (e.prop == "cgcs") {
        this.order_key = 105;
      } else {
        this.order_key = 106;
      }
      this.getGoods();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getGoods();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getGoods();
    },
  },
};
</script>

<style lang="less" scoped>
.info {
  text-align: left;
  margin-left: 10px;

  .tag {
    padding: 1px 8px;
    height: 17px;
    line-height: 17px;
    text-align: center;
    border-radius: 2px;
    margin-right: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
  }
  .c6 {
    font-size: 12px;
  }
}
.btn {
  width: 80px;
  height: 30px;
  border: 1px solid @themeColor;
  border-radius: 2px;
  color: @themeColor;
  cursor: pointer;
}
</style>
